import React from 'react';
import './icon.styles.css'


const Icon = () => {
  return (
    <div className="icon">
        <svg className='daisy-icon' data-name="daisy" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 77.7 71.05">
            <path d="M59.66 41.23c9.6.35 17.46-1.11 17.54-3.27s-7.64-4.19-17.24-4.54-8.27 0-17.55 3.27c6.24 2.54 7.64 4.19 17.25 4.54z" fill="#f9f9f9" stroke="#231f20" stroke-miterlimit="10"/>
            <path data-name="petal" d="M18.07 33.33C8.47 32.91.6 34.33.5 36.47s7.61 4.25 17.21 4.67 8.27 0 17.57-3.14c-6.21-2.59-7.6-4.25-17.21-4.67zM42.41 16.37c0-8.76-1.79-15.87-4-15.87s-4 7.11-4 15.87.34 7.53 4 15.88c2.37-5.77 4-7.12 4-15.88zM53.33 21.82c4.62-7.52 6.83-14.52 4.93-15.63s-7.18 4.09-11.81 11.6-3.68 6.63-4.93 15.63c5.08-3.76 7.19-4.08 11.81-11.6z" fill="#f9f9f9" stroke="#231f20" stroke-miterlimit="10"/>
            <path data-name="petal" d="M58.22 31.92c8.1-3.81 13.88-8.48 12.91-10.42s-8.3-.43-16.4 3.38-6.8 3.57-12.91 10.43c6.36-.43 8.31.43 16.4-3.39zM53.19 51.66c7.33 5.06 14.32 7.74 15.6 6s-3.63-7.3-11-12.36-6.5-4.09-15.6-6c3.48 5.22 3.66 7.29 11 12.36zM42.9 57.82c3.88 7.91 8.64 13.56 10.64 12.63s.47-8.1-3.4-16-3.64-6.66-10.65-12.64c.41 6.21-.47 8.1 3.41 16.01zM26.93 54.02c-3.71 8-5.09 15.18-3.07 16.07s6.66-4.85 10.37-12.84 2.89-7 3.08-16.07c-4.61 4.3-6.71 4.86-10.38 12.84zM19.2 43.72c-8.08 3.84-13.85 8.52-12.88 10.47s8.31.4 16.39-3.43 6.8-3.6 12.88-10.47c-6.36.45-8.31-.4-16.39 3.43zM23.69 23.97c-7.64-4.61-14.78-6.87-16-5s4.09 7.07 11.73 11.68 6.74 3.69 15.95 5c-3.77-5.01-4.04-7.06-11.68-11.68z" fill="#f9f9f9" stroke="#231f20" stroke-miterlimit="10"/>
            <path data-name="petal" d="M31.9 17.84c-4.55-7.56-9.79-12.81-11.7-11.71s.23 8.1 4.78 15.67 4.2 6.33 11.69 11.71c-.94-6.15-.23-8.11-4.77-15.67z" fill="#f9f9f9" stroke="#231f20" stroke-miterlimit="10"/>
            <ellipse cx="38.4" cy="37.24" rx="3.12" ry="5" fill="#f4ed71" stroke="#231f20" stroke-miterlimit="10"/>
        </svg>
    </div>
  );
}
export default Icon;

